<template>
    <div class="basic-file">
        <!-- 施工资料 -->
        <common-page :pageId="pageId"></common-page>
    </div>
</template>

<script>
import commonPage from './components/common'
export default {
    data() {
        return {
            // 施工资料 传3
            pageId: 3
        }
    },
    components: {
        commonPage
    }
}
</script>

<style lang="scss" scoped>
    
</style>
